import { PageType } from '@hubcms/domain-tracking';
import {
  isTagPage,
  MainContentQueryResultData,
  isExtraPageContext,
  isArticleContext,
  isSearchPage,
  isAuthorProfilePage,
} from '@hubcms/domain-cook';

export function getPageType(cookData: MainContentQueryResultData): PageType {
  if (isSearchPage(cookData)) {
    return 'search';
  }
  if (isAuthorProfilePage(cookData)) {
    return 'authoroverview';
  }
  if (isExtraPageContext(cookData.context)) {
    if (cookData.context?.fields.pageType === 'e-paper') {
      return 'digipaper';
    }
    return 'more';
  }
  if (isArticleContext(cookData.context)) {
    return 'detail';
  }
  if (isTagPage(cookData)) {
    return 'tagoverview';
  }
  return cookData.resolution.section.uniqueName === 'ece_frontpage' ? 'home' : 'sectionoverview';
}
