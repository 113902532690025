import {
  StoryElementWithChildrenWithoutRelation,
  StoryElementWithChildrenWithRelation,
  StoryElementWithoutChildrenWithoutRelation,
  StoryElementWithoutChildrenWithRelation,
} from './story-elements';

export * from './story-elements';

export * from './story-element-field';

export type StoryElement =
  | StoryElementWithChildrenWithRelation
  | StoryElementWithChildrenWithoutRelation
  | StoryElementWithoutChildrenWithRelation
  | StoryElementWithoutChildrenWithoutRelation;
