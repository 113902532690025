import {
  CookData,
  ElementsFragment,
  isArticleContext,
  isElementTextField,
  isElementWithRelation,
  isExtraPageContext,
  isTextualArticleContext,
  isVisualStoryContext,
} from '@hubcms/domain-cook';
import { getArticleAuthor, getArticleCounts, getArticleType } from '@hubcms/utils-tracking';

import { ArticleParams, createArticleParams } from '../domain/article-params';

import { combineAuthors } from './combineAuthors';

// method to calculate the articleBodyCharacterCount if there was none supplied by CUE via articleCounts
function calculateArticleBodyCharacterCount(elements: ElementsFragment[] | null | undefined): number {
  if (!elements) {
    return 0;
  }

  return elements
    .map(el => [...el.fields])
    .reduce((acc, fields) => [...acc, ...fields.filter(f => f.name.match(/intro|paragraph/))], [])
    .reduce((acc, field) => acc + (isElementTextField(field) ? field.value?.length : 0), 0);
}

export function getArticleParams(cookData: CookData): ArticleParams {
  const articleData: ArticleParams = createArticleParams();

  if (isArticleContext(cookData.context) || isExtraPageContext(cookData.context)) {
    if (isTextualArticleContext(cookData.context)) {
      const articleCounts = !isVisualStoryContext(cookData.context) ? cookData.context.fields.articleCounts : null;
      const { articleBodyCharacterCount, articleWordCount } = getArticleCounts(articleCounts);

      const combinedAuthors = combineAuthors(cookData.context.authors, cookData.context.fields?.freeTextAuthor);
      articleData.articleauthor = getArticleAuthor(combinedAuthors);
      articleData.articlebodycharactercount =
        articleBodyCharacterCount !== null
          ? articleBodyCharacterCount
          : calculateArticleBodyCharacterCount(cookData.context.elements);
      articleData.articlewordcount = articleWordCount !== null ? articleWordCount : null;
      articleData.articlesource = cookData.context.fields.displaySource;
      articleData.articlelayout = cookData.context.elements?.map(element => element.type) ?? [];
      articleData.articleimagecount = `${
        cookData.context.elements?.filter(el => {
          return el.type === 'image' || el.type === 'infoblock_image' || (el.type === 'hero_media' && isElementWithRelation(el));
        })?.length ?? 0
      }`;
      articleData.articlearchive = false;
    } else {
      articleData.articleauthor = getArticleAuthor(cookData.context.authors);
    }

    articleData.articlecontenttype = getArticleType(cookData.context.type)?.toLowerCase();
    articleData.articlepublicationdateinutc = cookData.context.updated;
    articleData.articletitle = cookData.context.title;
    if (isArticleContext(cookData.context)) {
      articleData.articleid = cookData.context.id;
      articleData.articletaglist = cookData.context.tags?.map(t => t.name) ?? [];

      articleData.dnagenre = cookData.context.fields.articleGenre ? [cookData.context.fields.articleGenre.toLowerCase()] : [];
      articleData.dnareason = cookData.context.fields.storyNature ? [cookData.context.fields.storyNature.toLowerCase()] : [];
      articleData.dnamedium = cookData.context.fields.mainMedium ? [cookData.context.fields.mainMedium.toLowerCase()] : [];
      articleData.dnauserneed = cookData.context.fields.userNeeds ? [cookData.context.fields.userNeeds.toLowerCase()] : [];
      articleData.dnaorientation = [];
      articleData.dnarationality = [];
      articleData.dnareadingtime = null;
    } else {
      articleData.articleid = null;
    }
  }

  const articleDataProxy = new Proxy(articleData, {
    get: (obj, prop: keyof ArticleParams) => (obj[prop] === '' ? null : obj[prop]),
  });

  return articleDataProxy;
}
