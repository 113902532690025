import type {
  StoryElement,
  StoryElementBasics,
  StoryElementBooleanField,
  StoryElementNumberField,
  StoryElementTextField,
  StoryElementWithChildren,
} from '../../base';
import type { PickTypenamed } from '../../utils/typenamed';

import type { ArticleRelationItem, ArticleRelationsFragment, OnBinary, OnGallery, OnPicture } from './article-relations';

export type OnStoryElementTextField = PickTypenamed<StoryElementTextField, 'name' | 'value'> & {
  annotations?: Array<Pick<StoryElementTextField['annotations'][number], 'index' | 'length' | 'name' | 'value'>>;
};

export type OnStoryElementNumberField = PickTypenamed<StoryElementNumberField, 'name' | 'numberValue'>;
export type OnStoryElementBooleanField = PickTypenamed<StoryElementBooleanField, 'name' | 'booleanValue'>;

export type OnStoryElementBasics = Pick<StoryElementBasics, 'id' | 'type'> & {
  fields: Array<
    PickTypenamed<StoryElementBasics['fields'][number], 'name'> &
      (OnStoryElementTextField | OnStoryElementNumberField | OnStoryElementBooleanField)
  >;
};

export type OnStoryElementWithRelation<T = ArticleRelationItem> = ArticleRelationsFragment<T>;
export type OnStoryElementWithChildren = Pick<StoryElementWithChildren, 'children'>;

export type ElementsFragment = PickTypenamed<StoryElement> &
  OnStoryElementBasics &
  // eslint-disable-next-line @typescript-eslint/ban-types
  (OnStoryElementWithRelation | OnStoryElementWithChildren | {});

export function isElementWithRelation<T = ArticleRelationItem>(
  element?: ElementsFragment,
): element is ElementsFragment & OnStoryElementWithRelation<T> {
  return (
    !!element &&
    (element.__typename === '_StoryElement_With_Children_With_Relation' ||
      element.__typename === '_StoryElement_Without_Children_With_Relation')
  );
}

export function isElementWithChildren(element?: ElementsFragment): element is ElementsFragment & OnStoryElementWithChildren {
  return (
    !!element &&
    (element.__typename === '_StoryElement_With_Children_With_Relation' ||
      element.__typename === '_StoryElement_With_Children_Without_Relation')
  );
}

export function isElementTextField(field?: ElementsFragment['fields'][number]): field is OnStoryElementTextField {
  return !!field && field.__typename === 'StoryElementTextField';
}
export function isElementNumberField(field?: ElementsFragment['fields'][number]): field is OnStoryElementNumberField {
  return !!field && field.__typename === 'StoryElementNumberField';
}
export function isElementBooleanField(field?: ElementsFragment['fields'][number]): field is OnStoryElementBooleanField {
  return !!field && field.__typename === 'StoryElementBooleanField';
}
export const isElementPicture = (data?: OnStoryElementWithRelation['relation']): data is OnPicture => {
  return !!data && data.__typename === 'Picture';
};
export const isElementGallery = (data?: OnStoryElementWithRelation['relation']): data is OnGallery => {
  return !!data && data.__typename === 'Gallery';
};
export const isElementBinary = (data: OnStoryElementWithRelation['relation']): data is OnBinary => {
  return !!data && data.__typename === 'Binary';
};
